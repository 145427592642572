import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import baseLogo from "../images/logo.png";

const NotFoundPage = () => {
  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? baseLogo;
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-white">
      <img src={logo} alt="Website Logo" className="mb-4 h-auto w-32" />
      <h4 className="text-4xl font-extrabold text-blue-700">
        404 Page Not Found
      </h4>
      <p className="text-md mt-4 text-gray-600">
        Sorry, the page you are looking for does not exist.
      </p>
      <Link
        to="/"
        className="mt-6 rounded-lg bg-blue-700 px-6 py-3 text-white shadow transition hover:bg-blue-800">
        Return to Homepage
      </Link>
    </div>
  );
};

export default NotFoundPage;
