import React, { useEffect } from "react";
import { HorizontalSwitch } from "react-horizontal-router-dom";

import CallCenter from "../components/CallCenter";
import { getBasePath } from "../utils";
import NavBarMain from "./NavBarMain";

const HorizontalRouterRoot = props => {
  const { roleRoutes, openLink, location } = props;
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById("horizontal-scroll");
      container.scrollLeft = container.scrollWidth - container.offsetWidth;
    }, 100);
  }, [location]);

  return (
    <div className="flex">
      <NavBarMain openLink={openLink} location={location} />
      <div className="flex h-screen flex-1 overflow-hidden">
        <div
          id="horizontal-scroll"
          className="scrollbar flex w-full overflow-x-auto overflow-y-hidden scroll-smooth">
          <div className="w-full whitespace-nowrap">
            <HorizontalSwitch root={getBasePath(location)}>
              {roleRoutes}
            </HorizontalSwitch>
          </div>
        </div>
        <CallCenter openLink={openLink} location={location} />
      </div>
    </div>
  );
};

export default HorizontalRouterRoot;
