import clsx from "clsx";
import React, { Children, cloneElement, useEffect, useState } from "react";

export const MenuItem = props => {
  const {
    children,
    onClick,
    onDefaultClick,
    currentPathname,
    route,
    active: propActive,
    isChild,
    iconName,
    label
  } = props;
  const hasChildren = !!Children.count(children);
  const [active, setActive] = useState(hasChildren ? null : null);
  useEffect(() => {
    if (hasChildren) {
      Children.map(children, (child, index) => {
        // Getting active menuItem from route. Using map as there is no some in Children
        if (
          currentPathname &&
          currentPathname.indexOf(child.props.route) > -1
        ) {
          setActive(index);
        }
      });
    }
  }, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    // Only if a MenuItem does not have children, push that location.
    if (!hasChildren && route && onDefaultClick) {
      onDefaultClick(route);
    }
  };

  return (
    <li
      className={clsx(
        "text-sm",
        isChild ? "px-2" : "px-2 py-1",
        propActive && "active"
      )}>
      <a
        onClick={handleClick}
        className={clsx(
          "group relative flex cursor-pointer items-center gap-2 rounded-full px-2",
          isChild && "my-1",
          !isChild && propActive && "bg-green-700",
          !propActive && "hover:bg-[#fff1] focus:bg-[#fff1]"
        )}>
        {iconName && (
          <i className="material-icons flex justify-center text-lg opacity-100">
            {iconName}
          </i>
        )}
        <span
          className={clsx(
            "flex-1",
            propActive && "opacity-100",
            !propActive && "opacity-65 group-hover:opacity-100"
          )}>
          {label}
        </span>
        {hasChildren && (
          <span
            className={clsx(
              "ml-auto flex items-center",
              propActive && "rotate-180"
            )}>
            <i className="material-icons">arrow_drop_down</i>
          </span>
        )}
      </a>
      {hasChildren ? (
        <ul
          className={clsx(
            "overflow-hidden transition-[max-height] duration-300 ease-in-out",
            propActive ? "max-h-[50rem]" : "max-h-0"
          )}>
          {Children.map(children, (child, index) =>
            cloneElement(child, {
              onClick: () => setActive(active === index ? null : index), // If the current tab is already selected, then unselect it (to collapse subMenu), else select the tab
              active: active === index,
              isChild: true,
              pathname: currentPathname
            })
          )}
        </ul>
      ) : null}
    </li>
  );
};

export const Menu = props => {
  const { children, currentPathname } = props;
  const [active, setActive] = useState(null);
  useEffect(() => {
    Children.map(children, (child, index) => {
      const hasChildren = !!Children.count(child.children);
      hasChildren &&
        Children.map(child.children, innerChild => {
          // Getting active menuItem from route. Using map as there is no some in Children
          if (
            innerChild &&
            currentPathname &&
            currentPathname.indexOf(innerChild.route) > -1
          ) {
            setActive(index);
          }
        });
      //Getting active menuItem from route   //Using map as there is no some in Children
      if (
        child &&
        currentPathname &&
        currentPathname.indexOf(child.route) > -1
      ) {
        setActive(index);
      }
    });
  }, []);

  return (
    <nav>
      <ul>
        {Children.map(children, (child, index) =>
          cloneElement(child, {
            onClick: () => setActive(active === index ? null : index), //If the current tab is already selected, then unselect it (to collapse subMenu), else select the tab
            active: active === index,
            currentPathname: currentPathname
          })
        )}
      </ul>
    </nav>
  );
};
